<template>
  <div class="notice">
        <top-bar :title="'任务详情'" :left="true"></top-bar>
        <van-tabs v-model="active" class="tab" title-active-color="#027eff" color="#027eff">
          <van-tab active = '0' title="任务详情" title-style="border-right: 1px solid #eee;"></van-tab>
          <van-tab active = '1' title="任务处置流程"></van-tab>
        </van-tabs>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showTimer" round position="bottom">
      <van-picker
          show-toolbar
          :columns="options"
          @cancel="showTimer = false"
          @confirm="onConfirm"
      />
    </van-popup>
    <div class="cont" v-show="active == '0'">
      <van-list offset="10">
        <van-cell class="itemCont">
            <van-cell-group >
              <van-cell class="title" center>
                <span>子火棋牌室</span><span class="right">(检查类)</span>
                <p>益乐路6号</p>
                <span>2021-06-30</span>
              </van-cell>
              <van-cell center>
                  <span class="contTitle">针对辖境内的沿街商铺进行消防检查安全</span>
              </van-cell>
            </van-cell-group>
        </van-cell>
        <van-cell class="itemBottom">
            <van-cell-group >
              <van-cell class="top" center>
                <span>检查类别</span>
                <span>沿街商铺消防安全检查</span>
              </van-cell>
              <van-checkbox-group v-model="result">
                <van-cell-group>
                  <van-cell
                      v-for="(item, index) in list"
                      clickable
                      :key="item"
                      :title="`${item}`"
                      @click="toggle(index)"
                  >
                    <template #right-icon>
                      <van-checkbox :name="item" ref="checkboxes" shape="square"/>
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-checkbox-group>
              <van-cell class="top" center>
                <span>处理结果描述</span>
                <van-field
                    v-model="message"
                    rows="2"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入结果"
                    input-align="left"
                    class="top-field"
                />
                <van-uploader v-model="fileList" />
                <p>任务是否已完成</p>
                <div style="display: flex">
                  <div class="true">
                    <input type="radio" id="0" checked @change="radioChange"/>
                    <label for="0">否</label>
                  </div>
                  <div class="false">
                    <input type="radio" id="1" @change="radioChange"/>
                    <label for="1">是</label>
                  </div>
                </div>
<!--                <van-radio-group v-model="radio" direction="horizontal">-->
<!--                  <van-radio name="1">否</van-radio>-->
<!--                  <van-radio name="2">是</van-radio>-->
<!--                </van-radio-group>-->
              </van-cell>
            </van-cell-group>
        </van-cell>
        <van-col :span="24">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-list>
<!--      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>-->
    </div>
    <div class="cont" v-show="active == '1'">
      <van-cell class="itemCont">
        <van-image src="https://img01.yzcdn.cn/vant/cat.jpeg"></van-image>
      </van-cell>
    </div>
<!--    <div class="cont" v-show="active == '1'">-->
<!--      <van-list offset="10">-->
<!--        <van-cell class="itemCont">-->
<!--          <van-cell-group >-->
<!--            <van-cell class="title titleDone" center>-->
<!--              <span>子火棋牌室</span><span class="right">(检查类)</span>-->
<!--              <p>2020.10.10</p>-->
<!--            </van-cell>-->
<!--            <van-cell center>-->
<!--              <span class="contTitle">-->
<!--                针对辖境内的沿街商铺进行消防检查安全针对辖境内的沿街商铺进行消防检查安全,-->
<!--                针对辖境内的沿街商铺进行消防检查安全针对辖境内的沿街商铺进行消防检查安全,针对辖境内的沿街商铺进行消防检查安全</span>-->
<!--            </van-cell>-->
<!--          </van-cell-group>-->
<!--        </van-cell>-->
<!--        <van-cell class="itemBottom">-->
<!--          <van-cell-group >-->

<!--            <van-cell class="top" center>-->
<!--              <span>处理结果描述</span>-->
<!--              <van-field-->
<!--                  v-model="message"-->
<!--                  rows="2"-->
<!--                  autosize-->
<!--                  type="textarea"-->
<!--                  maxlength="50"-->
<!--                  placeholder="请输入结果"-->
<!--                  input-align="left"-->
<!--                  class="top-field"-->
<!--              />-->
<!--              <van-uploader v-model="fileList" />-->
<!--              <p>任务是否已完成</p>-->
<!--              <van-radio-group v-model="radio" direction="horizontal">-->
<!--                <van-radio name="1">否</van-radio>-->
<!--                <van-radio name="2">是</van-radio>-->
<!--              </van-radio-group>-->
<!--            </van-cell>-->
<!--          </van-cell-group>-->
<!--        </van-cell>-->
<!--          <van-col :span="24">-->
<!--            <van-button type="info" size="large" round @click="submit">提交</van-button>-->
<!--          </van-col>-->
<!--      </van-list>-->
<!--      &lt;!&ndash;      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>&ndash;&gt;-->
<!--    </div>-->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapMutations } from 'vuex'
export default {
    components :{
        topBar
    },
     data() {
        return {
          active: '',
          loading: false,
          finished: false,
          showPicker: false,
          showTimer: false,
          limit: 10,
          page: 0,
          dataList: [],
          columns: ['检查类', '城管类', '公安类'],
          options: [],
          list: ['1.是否有人员违规住宿', '2.是否违规设置防盗窗影响逃生', '3.灭火器等消防器材是否配置完好、有效期内', '4.是否存在违规进行电气焊', '5.是否在商铺内、门口违规电动车充电'],
          result: [],
          message: '',
          fileList: [],
          radio: '1',
        }
     },
    methods: {
      onConfirm(value) {
        console.log(value)
        this.value = value
        this.showPicker = false
      },
      toggle(index) {
        this.$refs.checkboxes[index].toggle();
      },
      submit() {
        console.log('完成')
      },
      radioChange(e) {
        console.log(e.target.id)
      }
    }
}
</script>

<style lang="scss" scoped>
.notice {
    padding-top: 100px;
    .tab {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .itemCont {
      position: relative;
      padding: 15px 20px;
      //padding-top: 15px;
      margin-bottom: 30px;
      border-radius: 10px;
      .title {
        margin: 0;
        font-size: 32px;
        span:nth-child(2) {
          font-size: 30px;
        }
        p {
          height: 10px;
          font-size: 30px;
          color: #a2a2a2;
        }
        span:nth-child(4) {
          position: absolute;
          top: 0;
          right: 5px;
          font-size: 30px;
          color: #515151;
        }
      }
      .titleDone {
        p {
          margin: 0 0 40px 0;
        }
      }
      [class*=van-hairline]::after {
        border: none;
      }
      .van-cell__value {
        overflow: visible;
      }
    }
    .itemBottom {
      position: relative;
      padding: 0 20px;
      margin-bottom: 30px;
      border-radius: 10px;
      .top {
        margin: 0;
        font-size: 32px;
        span:nth-child(2) {
          position: absolute;
          top: 0;
          right: 5px;
          font-size: 30px;
          color: #515151;
        }
        .top-field {
          background-color: #f8f8f8;
          margin: 15px 0;
        }
        p {
          margin: 0 0 20px;
          font-size: 32px;
        }
        .van-radio--horizontal {
          margin-right: 120px;
        }
        input[type="radio"] + label::before {
          content: "\a0"; /*不换行空格*/
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          width: 40px;
          height: 40px;
          margin-right: 20px;
          border-radius: 50%;
          border: 5px solid #137ef7;
          text-indent: 2px;
          line-height: 1;
        }
        input[type="radio"]:checked + label::before {
          background-color: #137ef7;
          background-clip: content-box;
          padding: 3px;
        }
        input[type="radio"] {
          position: absolute;
          clip: rect(0, 0, 0, 0);
        }
        .true {
          margin-right: 100px;
        }
      }
      [class*=van-hairline]::after {
        border: none;
      }
      .van-cell__value {
        overflow: visible;
      }
    }
  }
  .van-button {
    margin-top: 80px;
  }
  .contTitle {
    display: block;
    padding-top: 10px;
    font-size: 30px;
    color: #a2a2a2;
  }
  .widthImg {
    height: 100%;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .filterBar {
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 28px;
    span:nth-child(2) {
      margin-left: 200px;
    }
  }

}
</style>
